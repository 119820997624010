<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>Trainings</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="vacancies_area">
            <div class="container">
                <div class="row m-2">COMING SOON</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../_helper/http-constants"
import axios from "axios"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"

export default {
    name: "Trainings",

    data() {
        return {
            user: "",

            // imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            // imgsrc: "http://localhost/backend/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,
        }
    },

    mounted() {
        scrollTop()
        request.get("/job").then((res) => {
            this.user = res.data
        })
    },
}
</script>
